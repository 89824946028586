import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../actions/auth/auth";
import { getHomeDataService } from '../../actions/home/home';

import Logo from "../../assets/images/logos.jpeg";

const Navbar = () => {
  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const homedata = useSelector(state => state.HomeReducer.homedata);

  useEffect(() => {
    dispatch(getHomeDataService(token))
  }, []);


  return (
    <aside
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
      id="sidenav-main"
    >
      <div class="sidenav-header">
        <i
          class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <a
          class="navbar-brand m-0"
          href="#"
        >
          <img src={Logo}
            class="navbar-brand-img h-200 rounded"
            alt="main_logo" />
          <span class="ms-1 font-weight-bold text-white">
            {homedata.nom}
          </span>
        </a>
      </div>
      <hr class="horizontal light mt-0 mb-2" />
      <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <li class="nav-item">
            <Link to="/">
              <a class="nav-link text-white">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">dashboard</i>
                </div>
                <span class="nav-link-text ms-1">Dashboard</span>
              </a>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/list-category">
              <a class="nav-link text-white">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">person</i>
                </div>
                <span class="nav-link-text ms-1">Categories</span>
              </a>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/list-menu">
              <a class="nav-link text-white">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">add</i>
                </div>
                <span class="nav-link-text ms-1">Menu</span>
              </a>
            </Link>
          </li>

          {/* <li class="nav-item">
              <Link to="/list-reservations">
                <a class="nav-link text-white">
                  <div
                    class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                  >
                    <i class="material-icons opacity-10">assignment</i>
                  </div>
                  <span class="nav-link-text ms-1">All Reservations</span>
                </a>
              </Link>
            </li> */}

          {/* <li class="nav-item">
            <Link to="/list-offre">
              <a class="nav-link text-white">
                <div
                  class="text-white text-center me-2 d-flex align-items-center justify-content-center"
                >
                  <i class="material-icons opacity-10">view_in_ar</i>
                </div>
                <span class="nav-link-text ms-1">Invite friends</span>
              </a>
            </Link>
          </li> */}
        </ul>
      </div>
      <div class="sidenav-footer position-absolute w-100 bottom-0 ">
        <div class="mx-3">
          <a
            class="btn btn-outline-primary mt-4 w-100"
            href="#"
            type="button"
          >
            Documentation
          </a>
        </div>
      </div>
    </aside>
  );
};
export default Navbar;
