import React, { useState } from "react";
// IMPORT DEPENDENCIES
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRestaurantService } from "../../actions/auth/auth";

import Loader from "../../components/loader/loader";
import Logo from "../../assets/images/logo.jpg";

const Login = () => {
  const Dispacth = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const token = useSelector((state) => state.AuthReducer.token);
  const role = useSelector((state) => state.AuthReducer.role);
  const loading = useSelector((state) => state.AuthReducer.loading);
  const errorserver = useSelector((state) => state.AuthReducer.error);

  const handleEmail = (e) => {
    setError(false);
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setError(false);
    setPassword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      email !== null &&
      email.trim() !== "" &&
      password !== null &&
      password.trim() !== "" &&
      email !== "" &&
      password !== ""
    ) {
      Dispacth(loginRestaurantService(email, password));
    } else {
      setError(true);
    }
  };

  let authRedirect = null;
  if (token && role === "restaurant") {
    authRedirect = <Redirect to="/" />;
  }

  return (
    <>
      <div class="container position-sticky z-index-sticky top-0">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg blur border-radius-xl top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
              <div class="container-fluid ps-2 pe-0">
                <a
                  class="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                  href="../pages/dashboard.html"
                >
                  MenuClick - Restaurant
                </a>
                <button
                  class="navbar-toggler shadow-none ms-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navigation"
                  aria-controls="navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon mt-2">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                  </span>
                </button>
                <div class="collapse navbar-collapse" id="navigation">
                  <ul class="navbar-nav mx-auto">
                  </ul>
                  <ul class="navbar-nav d-lg-flex d-none">
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="main-content  mt-0">
        <div
          class="page-header align-items-start min-vh-100 bg-one">
          <span class="mask bg-gradient-dark opacity-6"></span>
          <div class="container my-auto">
            <div class="row">
              <div class="col-lg-4 col-md-8 col-12 mx-auto">
                <div class="card z-index-0 fadeIn3 fadeInBottom">
                  <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                      <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                        Se connecter
                      </h4>
                      <br />
                    </div>
                  </div>
                  <div class="card-body">
                    <form role="form" onSubmit={submitHandler} class="text-start">
                      <div class="input-group input-group-outline my-3">
                        <input type="email" id="emailaddress" value={email} onChange={handleEmail} placeholder="Email" class="form-control" />
                      </div>
                      <div class="input-group input-group-outline mb-3">
                        <input type="password" value={password} onChange={handlePassword} placeholder="Mot de passe" id="password" class="form-control" />
                      </div>
                  
                      <div class="text-center loading-signin">

                        {loading ? <Loader /> : <button className="btn bg-gradient-primary w-100 my-4 mb-2" type="submit"> Connexion </button>}
                      </div>
                      {
                        error ? <div>
                          <br />
                          <span style={{ marginTop: '100px', textAlign: 'center', color: 'red' }}>Veuillez verifier vos informations</span>
                        </div>
                          :
                          null
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="footer position-absolute bottom-2 py-2 w-100">
            <div class="container">
              <div class="row align-items-center justify-content-lg-between">
                <div class="col-12 col-md-6 my-auto">
                  <div class="copyright text-center text-sm text-white text-lg-start">
                    © <script>2024</script>
                    <a
                      href="https://www.creative-tim.com"
                      class="font-weight-bold text-white"
                      target="_blank"
                    >
                      By Menuclick
                    </a>

                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                    <li class="nav-item">
                      <a
                        href="https://www.creative-tim.com"
                        class="nav-link text-white"
                        target="_blank"
                      >
                        Comment ça marche
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="https://www.creative-tim.com/presentation"
                        class="nav-link text-white"
                        target="_blank"
                      >
                       A propos
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="https://www.creative-tim.com/blog"
                        class="nav-link text-white"
                        target="_blank"
                      >
                        License
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="https://www.creative-tim.com/license"
                        class="nav-link pe-0 text-white"
                        target="_blank"
                      >
                        Versions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
export default Login;
