import * as actionTypes from '../../types/auth/auth'

const initialState = {
    token: null,
    role: null,
    username: null,
    name: null,
    error: null,
    loading: false
}

const ReducerAuth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_RESTAURANT_START:
            return {
                ...state,
                loading: true,
                error:null
            }
        case actionTypes.LOGIN_RESTAURANT_SUCCESS:
            return {
                ...state,
                token: action.token,
                username: action.username,
                name: action.name,
                loading: false,
                role: action.role,
                error:null
            }
        case actionTypes.LOGIN_RESTAURANT_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                token: null,
                username: null,
                role: null,
                name: null
            }
        default:
            return state
    }
}

export default ReducerAuth