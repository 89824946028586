import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import Checkbox from "react-custom-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { addMenuService, listCategoryService } from "../../actions/home/home";
import { Link, Redirect } from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const AddMenu = () => {

    const Dispatch = useDispatch();
    const token = useSelector((state) => state.AuthReducer.token);
    const loading_add_menu = useSelector((state) => state.HomeReducer.loading_add_menu);
    const addmenustatus = useSelector((state) => state.HomeReducer.addmenustatus);
    const loading_list_category = useSelector((state) => state.HomeReducer.loading_list_category);
    const categorylist = useSelector((state) => state.HomeReducer.categorylist);
    const homedata = useSelector((state) => state.HomeReducer.homedata);

    useEffect(() => {
        Dispatch(listCategoryService(homedata.id, token));
    }, []);


    const [nom, setNom] = useState("");
    const [category, setCategory] = useState(0);
    const [prix, setPrix] = useState("");
    const [error, setError] = useState(false);


    const handleNom = (e) => {
        setNom(e.target.value);
    };

    const handlePrix = (e) => {
        setPrix(e.target.value);
    };

    const handleCategory = (e) => {
        console.log(e.target.value)
        setCategory(e.target.value)

    }


    const handleSubmit = (e) => {
        if (nom !== "" && nom.trim() !== "" && prix !== "" && prix.trim() !== "" && prix !== 0 && category!==0) {
            Dispatch(addMenuService(category, nom, prix, token))
        } else {
            setError(true);
        }
    };


    let CategoryList = categorylist.map((item, index) => {
        return (
            <option value={item.id}>{item.name}</option>
        );
    });


    let redirect = null;

    if (addmenustatus) {
        redirect = <Redirect to="/list-menu" />
    }

    return (

        <>
            {redirect}
            <Navbar />

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Header />
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="card my-4 set-relative">
                                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div class="bg-gradient-dark shadow-primary border-radius-lg pt-4 pb-3 top-25">
                                        <h6 class="text-white text-capitalize ps-3 tag-mouse">
                                            <Link to='/list-menu' class="text-white text-capitalize ps-3 tag-mouse">
                                                Menu List
                                            </Link>
                                        </h6>
                                    </div>
                                </div>
                                <div class="modaparent">
                                    <div class="container-modal">
                                        <div class="interior">
                                            <div class="card-body">
                                                <div class="text-start">
                                                    <div class="row center-just">

                                                        <div class="input-group input-group-outline col-lg-6">
                                                            <label class="form-label">Category</label> <br />
                                                            <select class="form-control" onChange={handleCategory}>
                                                                <option value={0}>Selectionnez une Category</option>
                                                                {CategoryList}
                                                            </select>

                                                        </div>

                                                        <div class="input-group input-group-outline col-lg-6">
                                                            <label class="form-label">Nom</label> <br />
                                                            <input type="text" id="emailaddress" value={nom} onChange={handleNom} placeholder="Nom du restaurant" class="form-control" />
                                                        </div>

                                                        <div class="input-group input-group-outline col-lg-6">
                                                            <label class="form-label">Prix</label> <br />
                                                            <input type="number" value={prix} onChange={handlePrix} placeholder="Prix du Menu" class="form-control" />
                                                        </div>
                                                        <div class="input-group input-group-outline mb-3 col-lg-6"></div>
                                                    </div>
                                                    <div class="text-center">
                                                        {error ? (
                                                            <p style={{ color: "red" }}>Verifiez vos informations</p>
                                                        ) : null}
                                                        {loading_add_menu ?
                                                            <Loader /> : loading_list_category ? <Loader />
                                                                : (
                                                                    <button
                                                                        onClick={handleSubmit}
                                                                        className="btn bg-gradient-primary w-100 my-4 mb-2 max-300"
                                                                    >
                                                                        Ajouter{" "}
                                                                    </button>
                                                                )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>

    );
};
export default AddMenu;
