import * as actionTypes from '../../types/home/home'

const initialState = {
    loadingupload: false,
    error: false,
    filename: "",
    loading_add_category: false,
    addcategorystatus: false,

    loading_list_category: false,
    categorylist: [],

    loading_home_data: false,
    homedata: [],
    categorycount: 0,

    loading_list_menu: false,
    menulist: [],
    addmenustatus: false,


}

const ReducerHome = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                loadingupload: true,
                error: null
            }
        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loadingupload: false,
                error: false,
                filename: action.filename
            }
        case actionTypes.UPLOAD_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingupload: false
            }

        case actionTypes.GET_HOME_DATA_START:
            return {
                ...state,
                loading_home_data: true,
                error: false
            }
        case actionTypes.GET_HOME_DATA_SUCCESS:
            return {
                ...state,
                loading_home_data: false,
                homedata: action.homedata,
                categorycount: action.categorycount,
                error: false
            }
        case actionTypes.GET_HOME_DATA_FAILED:
            return {
                ...state,
                error: action.error,
                loading_home_data: false,
            }

        case actionTypes.ADD_CATEGORY_START:
            return {
                ...state,
                loading_add_category: true,
                error: false
            }
        case actionTypes.ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                loading_add_category: false,
                error: false,
                addcategorystatus: true
            }
        case actionTypes.ADD_CATEGORY_FAILED:
            return {
                ...state,
                error: action.error,
                addcategorystatus: false,
            }
        case actionTypes.LIST_CATEGORY_START:
            return {
                ...state,
                loading_list_category: true,
                addcategorystatus: false,
                error: false
            }
        case actionTypes.LIST_CATEGORY_SUCCESS:
            return {
                ...state,
                loading_list_category: false,
                error: false,
                categorylist: action.categorylist
            }
        case actionTypes.LIST_CATEGORY_FAILED:
            return {
                ...state,
                error: action.error,
                loading_list_category: false,
            }

        case actionTypes.ADD_MENU_START:
            return {
                ...state,
                loading_add_menu: true,
                error: false
            }
        case actionTypes.ADD_MENU_SUCCESS:
            return {
                ...state,
                loading_add_menu: false,
                error: false,
                addmenustatus: true
            }
        case actionTypes.ADD_MENU_FAILED:
            return {
                ...state,
                error: action.error,
                addmenustatus: false,
                loading_add_menu: false,
            }
        case actionTypes.LIST_MENU_START:
            return {
                ...state,
                loading_list_menu: true,
                addmenustatus: false,
                error: false
            }
        case actionTypes.LIST_MENU_SUCCESS:
            return {
                ...state,
                loading_list_menu: false,
                error: false,
                menulist: action.menulist
            }
        case actionTypes.LIST_MENU_FAILED:
            return {
                ...state,
                error: action.error,
                loading_list_menu: false,
            }

        default:
            return state
    }
}

export default ReducerHome