// IMPORT DEPENDENCIES & CONFIG
import Decode from 'jwt-decode';

// IMPORT URLS 
import { LOGIN_RESTAURANT_URL } from '../../urls/auth/auth';

// IMPORT TYPES
import { LOGIN_RESTAURANT_START, LOGIN_RESTAURANT_SUCCESS, LOGIN_RESTAURANT_FAILED, LOGOUT } from '../../types/auth/auth';

export const loginRestaurantStart = () => {
    return {
        type: LOGIN_RESTAURANT_START
    }
}

export const loginRestaurantSuccess = (token, name, role) => {
    return {
        type: LOGIN_RESTAURANT_SUCCESS,
        token: token,
        name: name,
        role: role
    }
}

export const loginRestaurantFail = (error) => {
    return {
        type: LOGIN_RESTAURANT_FAILED,
        error: error
    }
}

//expiration date
export const checkLoginTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut());
        }, expirationTime)
    }
}

export const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    localStorage.removeItem('name');
    return {
        type: LOGOUT
    }
}

export const loginRestaurantService = (email, password) => {
    return dispatch => {
        dispatch(loginRestaurantStart())
        fetch(LOGIN_RESTAURANT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username": `${email}`,
                "password": `${password}`,
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    var decoded = Decode(response.data.response)
                    console.log('Decodedd===', decoded);
                    const expirationDate = new Date(new Date().getTime() + decoded.exp * 1000)
                    localStorage.setItem('token', response.data.response);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('username', "Restaurant");
                    localStorage.setItem('role', "restaurant");
                    dispatch(loginRestaurantSuccess(response.data.response, decoded.userdata[0].email, "restaurant"));
                    dispatch(checkLoginTimeOut(decoded.exp));
                }
                else {
                    console.log(response)
                    dispatch(loginRestaurantSuccess(response.responseMessage))
                }
            })
            .catch(error => {
                dispatch(loginRestaurantFail(error.message))
            })
    }
}

export const loginCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const role = localStorage.getItem('role');
        if (!token) {
            dispatch(logOut())
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logOut())
            }
            else {
                dispatch(loginRestaurantSuccess(token, username, role))
                dispatch(checkLoginTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}
