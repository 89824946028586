import React from "react";

import "../../assets/css/loader.css";
import loaderGif from'../../assets/loaders/loader.gif';


const Loader = () => {
    return (
        <div className="loader-page">
            <img src={loaderGif} />
        </div>
    )
}
export default Loader;