import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Decode from 'jwt-decode'
import { souscrireOffreService } from '../../actions/home/home'

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const Panel = () => {

  const dispatch = useDispatch()

  const loading_home_data = useSelector(state => state.HomeReducer.loading_home_data);
  const categorycount = useSelector(state => state.HomeReducer.categorycount);

  return (
    <>
      <Navbar />
      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Header />

        {
          loading_home_data ?
            <div className="loader-parent height-full">
              <Loader />
            </div> :

            <div class="container-fluid py-4">
              <div class="row">
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div class="card">
                    <div class="card-header p-3 pt-2">
                      <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                        <i class="material-icons opacity-10">weekend</i>
                      </div>
                      <div class="text-end pt-1">
                        <p class="text-sm mb-0 text-capitalize">Menu</p>
                        <h4 class="mb-0">0</h4>
                      </div>
                    </div>
                    <hr class="dark horizontal my-0" />
                    <div class="card-footer p-3">
                      <p class="mb-0"><span class="text-success text-sm font-weight-bolder">2 </span>minutes ago</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div class="card">
                    <div class="card-header p-3 pt-2">
                      <div class="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                        <i class="material-icons opacity-10">person</i>
                      </div>
                      <div class="text-end pt-1">
                        <p class="text-sm mb-0 text-capitalize">Scans</p>
                        <h4 class="mb-0">2,300</h4>
                      </div>
                    </div>
                    <hr class="dark horizontal my-0" />
                    <div class="card-footer p-3">
                      <p class="mb-0"><span class="text-success text-sm font-weight-bolder">+3% </span>Minutes ago</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div class="card">
                    <div class="card-header p-3 pt-2">
                      <div class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                        <i class="material-icons opacity-10">person</i>
                      </div>
                      <div class="text-end pt-1">
                        <p class="text-sm mb-0 text-capitalize">Categories</p>
                        <h4 class="mb-0">{categorycount}</h4>
                      </div>
                    </div>
                    <hr class="dark horizontal my-0" />
                    <div class="card-footer p-3">
                      <p class="mb-0"><span class="text-danger text-sm font-weight-bolder">-2%</span> Minutes ago</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-sm-6">
                  <div class="card">
                    <div class="card-header p-3 pt-2">
                      <div class="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                        <i class="material-icons opacity-10">weekend</i>
                      </div>
                      <div class="text-end pt-1">
                        <p class="text-sm mb-0 text-capitalize">Avis</p>
                        <h4 class="mb-0">$103,430</h4>
                      </div>
                    </div>
                    <hr class="dark horizontal my-0" />
                    <div class="card-footer p-3">
                      <p class="mb-0"><span class="text-success text-sm font-weight-bolder">+5% </span>Minutes ago</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-4 col-md-6 mt-4 mb-4">
                  <div class="card z-index-2 ">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                      <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                        <div class="chart">
                          <canvas id="chart-bars" class="chart-canvas" height="170"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h6 class="mb-0 ">Avis Journaliers</h6>
                      <p class="text-sm ">Avis d'aujourd'hui</p>
                      <hr class="dark horizontal" />
                      <div class="d-flex ">
                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                        <p class="mb-0 text-sm"> campaign sent 2 days ago </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4 mb-4">
                  <div class="card z-index-2  ">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                      <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                        <div class="chart">
                          <canvas id="chart-line" class="chart-canvas" height="170"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h6 class="mb-0 "> Scanne journaliers </h6>
                      <p class="text-sm "> (<span class="font-weight-bolder">+15%</span>) Scans aujourd'hui. </p>
                      <hr class="dark horizontal" />
                      <div class="d-flex ">
                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                        <p class="mb-0 text-sm"> updated 4 min ago </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mt-4 mb-3">
                  <div class="card z-index-2 ">
                    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                      <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                        <div class="chart">
                          <canvas id="chart-line-tasks" class="chart-canvas" height="170"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h6 class="mb-0 ">Total Vues</h6>
                      <p class="text-sm ">0</p>
                      <hr class="dark horizontal" />
                      <div class="d-flex ">
                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                        <p class="mb-0 text-sm">just updated</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </main>
    </>
  )
};
export default Panel;