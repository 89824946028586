import Decode from 'jwt-decode';
import S3FileUpload from "react-s3";
import { S3_BUCKET, REGION, ACCESS_KEY, SECRET_ACCES_KEY, DIRECTORY_NAME } from "../../assets/utils/config";

//IMPORT URL
import { GET_CATEGORY_URL, ADD_CATEGORY_URL, GET_HOME_DATA_URL, ADD_MENU_URL, GET_MENU_URL} from "../../urls/home/home";

// IMPORT TYPES
import {
    UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL,ADD_CATEGORY_FAILED, ADD_CATEGORY_START, ADD_CATEGORY_SUCCESS,
    LIST_CATEGORY_START, LIST_CATEGORY_FAILED, LIST_CATEGORY_SUCCESS, GET_HOME_DATA_START, GET_HOME_DATA_SUCCESS, GET_HOME_DATA_FAILED,
    ADD_MENU_START, ADD_MENU_SUCCESS, ADD_MENU_FAILED, LIST_MENU_START, LIST_MENU_SUCCESS, LIST_MENU_FAILED
} from "../../types/home/home";

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCES_KEY,
    dirName: DIRECTORY_NAME
}

export const uploadFileStart = () => {
    return {
        type: UPLOAD_FILE_START
    }
}

export const uploadFileSuccess = (filename) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        filename: filename
    }
}

export const uploadFileFail = () => {
    return {
        type: UPLOAD_FILE_FAIL
    }
}

export const uploadFileService = (data) => {

    console.log("dataaa====>>>", data)
    return dispatch => {
        dispatch(uploadFileStart())
        S3FileUpload.uploadFile(data, config)
            .then(response => {
                console.log(response)
                if (response.result.ok) {
                    dispatch(uploadFileSuccess(response.location))
                }
                else {
                    dispatch(uploadFileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}


export const getHomeDataStart = () => {
    return {
        type: GET_HOME_DATA_START
    }
}

export const getHomeDataSuccess = (homedata,categorycount) => {
    return {
        type: GET_HOME_DATA_SUCCESS,
        homedata:homedata,
        categorycount: categorycount
    }
}

export const getHomeDataFail = (error) => {
    return {
        type: GET_HOME_DATA_FAILED,
        error: error
    }
}

export const getHomeDataService = (token) => {
    let decoded = Decode(token)
    return dispatch => {
        dispatch(getHomeDataStart())
        fetch(GET_HOME_DATA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "userId": decoded.userdata[0].id
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getHomeDataSuccess(response.data.response, response.data.responsecategory))
                }
                else {
                    dispatch(getHomeDataFail("error"))
                }
            })
            .catch(error => {
                dispatch(getHomeDataFail(error.message))
            })
    }
}


export const addCategoryStart = () => {
    return {
        type: ADD_CATEGORY_START
    }
}

export const addCategorySuccess = () => {
    return {
        type: ADD_CATEGORY_SUCCESS
    }
}

export const addCategoryFail = (error) => {
    return {
        type: ADD_CATEGORY_FAILED,
        error: error
    }
}

export const addCategoryService = (restaurantId, name,token) => {
    return dispatch => {
        dispatch(addCategoryStart())
        fetch(ADD_CATEGORY_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "name": name,
                "restaurantId":restaurantId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addCategorySuccess());
                }
                else {
                    dispatch(addCategoryFail("error"))
                }
            })
            .catch(error => {
                dispatch(addCategoryFail(error.message))
            })
    }
}

export const listCategoryStart = () => {
    return {
        type: LIST_CATEGORY_START
    }
}

export const listCategorySuccess = (categorylist) => {
    return {
        type: LIST_CATEGORY_SUCCESS,
        categorylist:categorylist
    }
}

export const listCategoryFail = (error) => {
    return {
        type: LIST_CATEGORY_FAILED,
        error: error
    }
}

export const listCategoryService = (restaurantId,token) => {
    return dispatch => {
        dispatch(listCategoryStart())
        fetch(GET_CATEGORY_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "restaurantId":restaurantId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(listCategorySuccess(response.data.response));
                }
                else {
                    dispatch(listCategoryFail("error"))
                }
            })
            .catch(error => {
                dispatch(listCategoryFail(error.message))
            })
    }
}

export const addMenuStart = () => {
    return {
        type: ADD_MENU_START
    }
}

export const addMenuSuccess = () => {
    return {
        type: ADD_MENU_SUCCESS
    }
}

export const addMenuFail = (error) => {
    return {
        type: ADD_MENU_FAILED,
        error: error
    }
}

export const addMenuService = (categoryId, name, prix, token) => {
    return dispatch => {
        dispatch(addMenuStart())
        fetch(ADD_MENU_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "name": name,
                "categoryId":categoryId,
                "prix":prix
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addMenuSuccess());
                }
                else {
                    dispatch(addMenuFail("error"))
                }
            })
            .catch(error => {
                dispatch(addMenuFail(error.message))
            })
    }
}

export const listMenuStart = () => {
    return {
        type: LIST_MENU_START
    }
}

export const listMenuSuccess = (menulist) => {
    return {
        type: LIST_MENU_SUCCESS,
        menulist:menulist
    }
}

export const listMenuFail = (error) => {
    return {
        type: LIST_MENU_FAILED,
        error: error
    }
}

export const listMenuService = (restaurantId,token) => {
    return dispatch => {
        dispatch(listMenuStart())
        fetch(GET_MENU_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "restaurantId":restaurantId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(listMenuSuccess(response.data.response));
                }
                else {
                    dispatch(listMenuFail("error"))
                }
            })
            .catch(error => {
                dispatch(listMenuFail(error.message))
            })
    }
}

