import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {listMenuService} from "../../actions/home/home";
import { Link, Redirect } from "react-router-dom";
import Switch from "react-switch";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const MenuList = () => {

  const dispatch = useDispatch();

  const token = useSelector((state) => state.AuthReducer.token);
  const loading_list_menu = useSelector((state) => state.HomeReducer.loading_list_menu);
  const menulist = useSelector((state) => state.HomeReducer.menulist);
  const homedata = useSelector((state) => state.HomeReducer.homedata);
 
  useEffect(() => {
    dispatch(listMenuService(homedata.id,token));
  }, []);


  let MenuList = menulist.map((item, index) => {
    return (
      <tr key={index}>


<td>
          <div class="d-flex px-2 py-1">
            
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-sm">{item.category.name} </h6>
              <p class="text-xs text-secondary mb-0">Category</p>
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex px-2 py-1">
            
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-sm">{item.name}</h6>
              <p class="text-xs text-secondary mb-0">Nom</p>
            </div>
          </div>
        </td>

    

        <td>
          <div class="d-flex px-2 py-1">
            
            <div class="d-flex flex-column justify-content-center">
              <h6 class="mb-0 text-sm">{item.prix} FCFA</h6>
              <p class="text-xs text-secondary mb-0">Prix</p>
            </div>
          </div>
        </td>

        <td class="align-middle">
            <a
            onClick={() => {
             // openEditOffre(item)
             // setVisibleUpdateOffer(true)
               }}
            class="btn btn-outline-primary btn-sm mb-0 me-3"> Modifier</a>
        </td>

        <td class="align-middle">
          <a
            class="text-secondary font-weight-bold text-xs"
            data-toggle="tooltip"
            onClick={() => {if(window.confirm('Are you sure to delete this item?')){
              console.log("delete item");
              }
               ;}}
          >
          <a class="btn btn-outline-danger btn-sm mb-0 me-3">Supprimer</a>
          </a>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Navbar />
      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Header />
        <div class="container-fluid py-4">
          <div class="row">
            <div class="col-12">
              <div class="card my-4 set-relative">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-gradient-dark shadow-primary border-radius-lg pt-4 pb-3 top-25">
                   {
                    <h6
                    class="text-white text-capitalize ps-3 tag-mouse"
                  >
                    <Link to='/add-menu' class="text-white text-capitalize ps-3 tag-mouse">
                   + Ajouter un Menu
                    </Link>
                  </h6>
                   }
                </div>
                </div>
                <div class="card-body px-0 pb-2">
                  <div class="table-responsive p-0 set-relative">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Category
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Nom
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Prix
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Action
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading_list_menu ? (
                          <div className="loader-parent">
                            <Loader />
                          </div>
                        ) : (
                          MenuList
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MenuList;
