export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

// GET_HOME_DATA
export const GET_HOME_DATA_START = "GET_HOME_DATA_START";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAILED = "GET_HOME_DATA_FAILED";


// ADD_CATEGORY_DATA
export const ADD_CATEGORY_START = "ADD_CATEGORY_START";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILED = "ADD_CATEGORY_FAILED";

// LIST_CATEGORY_DATA
export const LIST_CATEGORY_START = "LIST_CATEGORY_START";
export const LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
export const LIST_CATEGORY_FAILED = "LIST_CATEGORY_FAILED";

// ADD_MENU_DATA
export const ADD_MENU_START = "ADD_MENU_START";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_FAILED = "ADD_MENU_FAILED";

// LIST_MENU_DATA
export const LIST_MENU_START = "LIST_MENU_START";
export const LIST_MENU_SUCCESS = "LIST_MENU_SUCCESS";
export const LIST_MENU_FAILED = "LIST_MENU_FAILED";